<template>
  <b-container>
    <h1>
      FAQs
    </h1>
    <b-row>
      <b-col>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-1"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-1'"
                title-tag="h2"
                class="accordion-title"
              >
                What is an Organization?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-1"
              accordion="faq"
            >
              <b-card-body>
                <li> An Organization determines what will be prefixed to the URL of the upload </li>
                <li>
                  Once you select an organization from the dropdown,
                  the URL field will become active and the organization's
                  shortcode will be shown next to the URL input field.
                </li>
                <li>
                  The organization shortcode is either your username (for the Personal organization)
                  or some shortened version of the organization's name.
                </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-2"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-2'"
                title-tag="h2"
                class="accordion-title"
              >
                What is a URL?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-2"
              accordion="faq"
            >
              <b-card-body>
                <li> A URL is a unique resource locator. </li>
                <li> It is how your viewers will access your flipbook. </li>
                <li> A URL is a mandatory field and it must be entered in order to submit the file.</li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-3"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-3'"
                title-tag="h2"
                class="accordion-title"
              >
                What does "URL already exists" mean?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-3"
              accordion="faq"
            >
              <b-card-body>
                <li> This means that the URL has already been used for another flipbook from this organization. </li>
                <li> Please change the URL entered, or change the organization it belongs to. </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-4"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-4'"
                title-tag="h2"
                class="accordion-title"
              >
                Why can't I enter uppercase letters, spaces or special characters in the URL field?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-4"
              accordion="faq"
            >
              <b-card-body>
                <li>
                  URLs are subject to a number of internet standards. Because of this,
                  the URL field can not accept uppercase letters, spaces, or special characters.
                </li>
                <li>
                  When you click off of the URL field, uppercase letters will be converted to lowercase,
                  spaces will be converted to a "-", and special characters will be removed.
                </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-5"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-5'"
                title-tag="h2"
                class="accordion-title"
              >
                How do I upload a file?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-5"
              accordion="faq"
            >
              <b-card-body>
                <li> Visit the Upload tab at the top of the screen </li>
                <li> Choose an organization </li>
                <li> Enter a unique URL for the flipbook </li>
                <li> Click on the Browse button to choose a file </li>
                <li>
                  Click the Upload button to upload the file.
                  You will be redirected to the file's edit page once the upload is complete.
                </li>
                <li> NOTE: The file must be of PDF format </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-6"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-6'"
                title-tag="h2"
                class="accordion-title"
              >
                What are the alt-text and long description fields for?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-6"
              accordion="faq"
            >
              <b-card-body>
                <li>
                  Alt-text and long description are text descriptions of the page,
                  designed for users with disabilities using a screen reader.
                </li>
                <li>
                  The alt text should include a one or a two sentence summary of the page so that
                  a screen reader can give an overview of the page content without reading the entire page.
                </li>
                <li>
                  The long description of the page should include the entire text of the page,
                  and descriptions of any non-decorative images on the page.
                </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-7"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-7'"
                title-tag="h2"
                class="accordion-title"
              >
                What is a double page?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-7"
              accordion="faq"
            >
              <b-card-body>
                <li>
                  Some PDF files are designed to print as a booklet and
                  combine two pages in print into one digital page
                </li>
                <li>
                  If your file is in this format,
                  the double page box should be checked for any PDF page that is a double page.
                </li>
                <li> There is an example of a double page below. </li>
                <b-img
                  class="w-50 p-3"
                  left
                  src="../../assets/double-page-example.jpg"
                />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div
          id="faq"
          class="accordion"
        >
          <b-card
            id="faq-8"
            no-body
            header-bg-variant="secondary"
            border-variant="secondary"
            header-text-variant="white"
          >
            <template #header>
              <b-card-title
                v-b-toggle="'faq-8'"
                title-tag="h2"
                class="accordion-title"
              >
                What is the scale factor and when should I change it?
              </b-card-title>
            </template>
            <b-collapse
              id="faq-8"
              accordion="faq"
            >
              <b-card-body>
                <li>
                  This application assumes the uploaded PDFs are designed
                  to be printed portrait on an 8.5x11 paper size
                </li>
                <li> The scale factor is a decimal representation of the width to height ratio for each page. </li>
                <li>
                  The scale factor is used to properly size the flipbook on the
                  screen by dividing the width of each page by the scale factor.
                </li>
                <li>
                  If your PDF is not designed for portrait in an 8.5x11 paper size,
                  you will need to adjust the scale until the flipbook preview looks correct.
                </li>
                <li> For example, landscape 11x8.5 is a scale factor of 1.28 </li>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: `Help`,
};
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 1.8rem;
  }

  .card-title {
    margin-bottom: 0;
  }

  .card {
    margin-bottom: 1.2rem;
  }
</style>
